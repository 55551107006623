<template>
  <div class="category-wrapper clearfix">
    <div class="archives-content">
      <div class="title">Category</div>
      <ul class="main">
        <li class="mainLi" v-for="item in categoryList" :key="item.tagname">
          <p :id="item.tagname">{{ item.tagname }}</p>
          <ul className="article-ul">
            <li
              className="article-li"
              v-for="subItem in item.list"
              :key="subItem.id"
            >
              <span>{{ subItem.createtime }}</span>
              <router-link :to="'/detail/' + subItem.id" className="titleLink">
                <span className="subtitle">{{ subItem.title }}</span>
              </router-link>
              <span className="tag">{{ item.tagname }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="archives-nav">
      <div className="top">Content</div>
      <ul className="container">
        <li
          className="year-li"
          v-for="item in categoryList"
          :key="item.tagname"
          @click="scrollToAnchor(item.tagname)"
        >
          {{ item.tagname }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Category',
  data() {
    return {
      text: 'CategoryCategoryCategory',
    }
  },
  computed: mapState({
    categoryList: (state) => state.category.categoryList,
  }),
  mounted() {
    //映射等同于 this.$store.dispatch('category/getCategoryList')
    this.getCategoryList()
    // this.$store.dispatch('category/getCategoryList')
  },
  methods: {
    ...mapActions({
      getCategoryList: 'category/getCategoryList',
    }),
    scrollToAnchor(anchorName) {
      console.log(anchorName)
      if (anchorName) {
        let anchorElement = document.getElementById(anchorName)
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import url('./category.less');
</style>