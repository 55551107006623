<template>
  <div class="info-wrapper">
    <p className="info-title">ABOUT ME</p>
    <img className="info-head" src="../../assets/img/zelda.png" alt="" />
    <p className="info-explain">
      我是一只前端的小怪，如同塞尔达中的林克一样，一直在守护的道路上打怪升级！
    </p>
  </div>
</template>

<script>
</script>

<style lang="less">
@import url('./Info.less');
</style>