function timeToDate(time){
  const createTime = new Date(time);
  const year = createTime.getFullYear();
  const month = addZero(createTime.getMonth() + 1);
  const day = addZero(createTime.getDate());
  const hour = addZero(createTime.getHours());
  const minute = addZero(createTime.getMinutes());
  const second = addZero(createTime.getSeconds());
  return (year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second)
}

function addZero(number){
  if (Number(number) < 10) {
      return ('0' + number)
  }
  return number
}

export {
  timeToDate
}