<template>
  <div class="archives-wrapper clearfix">
    <div class="archives-content">
      <div class="title">Archives</div>
      <ul class="main-ul">
        <li class="main-li" v-for="item in archivesList" :key="item.year">
          <p :id="item.year" className="years">{{ item.year }}</p>
          <ul className="article-ul">
            <li
              v-for="subItem in item.data"
              :key="subItem.id"
              className="article-li"
            >
              <span>{{ subItem.createtime }}</span>
              <router-link className="titleLink" :to="'/detail/' + subItem.id">
                <span className="sub-title">{{ subItem.title }}</span>
              </router-link>
              <router-link
                v-for="tagItem in subItem.tag"
                :key="tagItem.id"
                :to="'/category/' + tagItem.id"
              >
                <span className="tag">{{ tagItem.tagname }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="archives-nav">
      <div className="top">Content</div>
      <ul className="container">
        <li
          v-for="item in archivesList"
          :key="item.year"
          className="year-li"
          @click="scrollToAnchor(item.year)"
        >
          {{ item.year }} ({{ item.length }})
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Archives',
  data() {
    return {}
  },
  computed: mapState({
    archivesList(state) {
      return state.archives.archivesList
    },
  }),
  mounted() {
    this.getArchivesList()
  },
  methods: {
    ...mapActions({
      getArchivesList: 'archives/getArchivesList',
    }),
    scrollToAnchor(anchorName) {
      console.log(anchorName)
      if (anchorName) {
        let anchorElement = document.getElementById(anchorName)
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import url('./archives.less');
</style>