import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import less from 'less'
import Antd from 'ant-design-vue/es'
import store from './store/index'
import "ant-design-vue/dist/antd.css"
import "styles/reset.css"
import "styles/border.css"

createApp(App)
  .use(router)
  .use(store)
  .use(Antd)
  .use(less)
  .mount('#app')
