import { createStore } from 'vuex'
import home from './modules/home'
import detail from './modules/detail'
import category from './modules/category'
import archives from './modules/archives'
import about from './modules/about'

export default createStore({
  modules: {
    home,
    detail,
    category,
    archives,
    about
  }
})