import request from "../../utils/axios";

export default {
  namespaced: true,
  state: () => ({
    categoryList: [],
  }),
  actions: {
    getCategoryList({ commit }) {
      request({
        method: "get",
        url: "/api/blog/getlistbycategory",
      }).then((res) => {
        console.log(res);
        if (res.data.code === "001") {
          var result = res.data.data;
          commit("changeCategoryList", { result });
        }
      });
    },
  },
  mutations: {
    changeCategoryList(state, payload) {
      state.categoryList = payload.result;
    },
  },
};
