<template>
  <div class="container-bg">
    <!-- {{blogCount}}
    <p @click="addCount">点击试试</p> -->
    <div class="body clearfix">
      <div class="left-nav">
        <div className="userBox">
          <img className="userHead" src="../../assets/img/zelda.png" alt="" />
          <h4 className="userName">xGgg</h4>
          <span className="job">weeeeeeeeeeeeeb </span>
        </div>
      </div>
      <div class="right-content">
        <ul class="blog-list">
          <li className="blogListItem" v-for="item in list" :key="item.id">
            <router-link :to="'/detail/'+item.id">
              <p className="title">{{ item.title }}</p>
              <p className="abstract">{{ item.text }}</p>
            </router-link>
            <div className="listBottom">
              <!-- <img className="listBottomHead" src="{userHeadImg}" alt="" />
              <span className="listBottomName">{{item.author}}</span> -->
              <span className="listBottomTime">{{ item.changeTime }}</span>
              <span className="readBox">阅读数 {{ item.looknumber }}</span>
            </div>
          </li>
        </ul>
        <a-pagination
          :defaultPageSize="pageSize"
          showQuickJumper
          :total="blogCount"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      pageSize: 10,
      total: 200,
    }
  },
  computed: mapState({
    blogCount(state) {
      return state.home.totalBlogCount
    },
    list(state) {
      return state.home.listData
    },
  }),
  mounted() {
    this.getBlogList({
      page: 0,
    })
  },
  methods: {
    ...mapActions({
      // 将 `this.getBlogList(payload)` 映射为 `this.$store.dispatch('home/getBlogList', payload)`
      getBlogList : 'home/getBlogList'
    }),
    // ...mapMutations([
    //   'changeBlogList'
    // ]),
    addCount() {
      // this.$store.dispatch('getBlogList')
    },
    pageChange(pageValue) {
      this.getBlogList({page: pageValue - 1})
      console.log(pageValue)
    },
  },
}
</script>

<style lang="less" scoped>
@import url('./home.less');
</style>