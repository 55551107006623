<template>
  <p>toRef demo {{ ageRef }} {{ state.name }} {{ state.age }}</p>
  <input type="text" v-model="sth" />
</template>

<script>
import { reactive, toRef } from "vue";
export default {
  name: "testv3",
  setup() {
    const state = reactive({
      age: 20,
      name: "xggg",
    });

    const ageRef = toRef(state, "age");

    setTimeout(() => {
      state.age = 25;
    }, 2000);

    setTimeout(() => {
      ageRef.value = 30;
    }, 4000);

    return {
      state,
      ageRef,
    };
  },
};
</script>

<style lang="less" scoped>
</style>