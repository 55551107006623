import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../pages/index/index.vue'
import Home from '../pages/home/home.vue'
import Category from '../pages/category/category.vue'
import Archives from '../pages/archives/archives.vue'
import About from '../pages/about/about.vue'
import Detail from '../pages/detail/detail.vue'
import Test from '../pages/test/test.vue'
import TestV3 from '../pages/test/testV3.vue'

export default createRouter({
  history: createWebHashHistory(),
  // base: '/vue3blog/',
  routes: [
    {
      path: '/',
      component: Index,
      children: [
        {
          path: '',
          component: Home
        },
        {
          path: 'category',
          component: Category
        },
        {
          path: 'archives',
          component: Archives
        },
        {
          path: 'detail/:id',
          component: Detail 
        },
        {
          path: 'about',
          component: About
        },
        {
          path: 'test',
          component: Test
        },
        {
          path: 'testv3',
          component: TestV3
        },
      ]
    },
    // {
    //   path: '/home',
    //   component: Home
    // }
  ]
})