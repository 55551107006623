<template>
  <div class="header-bg">
    <div class="header-wrapper clearfix">
      <span class="logo">xggg</span>
      <ul class="tab-wrapper">
        <router-link to="/about" active-class="selected">
          <li className="tab-item">
            <MehOutlined />
            关于我
          </li>
        </router-link>
        <router-link to="/archives" active-class="selected">
          <li className="tab-item">
            <FolderOutlined />
            文章
          </li>
        </router-link>
        <router-link to="/category" active-class="selected">
          <li className="tab-item">
            <UnorderedListOutlined />
            分类
          </li>
        </router-link>
        <router-link to="/" exact-active-class="selected">
          <li className="tab-item">
            <HomeOutlined />
            首页
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  MehOutlined,
  FolderOutlined,
  UnorderedListOutlined,
  HomeOutlined,
} from "@ant-design/icons-vue";
export default {
  name: "Header",
  components: {
    MehOutlined,
    FolderOutlined,
    UnorderedListOutlined,
    HomeOutlined,
  },
};
</script>

<style lang="less">
@import url("~styles/base.less");
@import url("./Header.less");
</style>