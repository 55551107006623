<template>
  <div class="detail-wrapper clearfix">
    <div class="detail-content">
      <div class="top">
        <div class="title">{{ title }}</div>
        <p className="explain">
          <ReadOutlined style="margin-right: 8px" />
          {{ changeTime }}
        </p>
      </div>
      <div class="content" v-html="content"></div>
    </div>
    <div class="info-content">
      <Info></Info>
    </div>
  </div>
</template>

<script>
import Info from "common/info/Info.vue";
import { ReadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Detail",
  components: {
    Info,
    ReadOutlined,
  },
  computed: mapState({
    title(state) {
      return state.detail.title;
    },
    content(state) {
      return state.detail.content;
    },
    changeTime(state) {
      return state.detail.changeTime;
    },
  }),
  mounted() {
    const id = this.$route.params.id;
    console.log(this.$store.state.detail);
    this.getDetailData({
      id,
    });
  },
  methods: {
    ...mapActions({
      getDetailData: "detail/getDetailData",
    }),
  },
};
</script>

<style lang="less" scoped>
@import url("./detail.less");
</style>