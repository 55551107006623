import request from "../../utils/axios";

export default {
  namespaced: true,
  state: () => ({
    archivesList: [],
  }),
  actions: {
    getArchivesList({ commit }) {
      request({
        method: "get",
        url: "/api/blog/getlistbyyear",
      }).then((res) => {
        console.log(res);
        if (res.data.code === "001") {
          var result = res.data.data;
          let changeResult = [];
          for (const x of result) {
              console.log(x)
              if (x.data.length > 0) {
                  changeResult.push(x)
              }
          }
          commit('changeArchivesList',{ changeResult });
        }
      });
    },
  },
  mutations: {
    changeArchivesList(state, payload){
      state.archivesList = payload.changeResult
    }
  },
};
