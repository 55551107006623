<template>
  <Header></Header>
  <div class="contentBody">
    <router-view></router-view>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "common/header/Header.vue";
import Footer from "common/footer/Footer.vue";

export default {
  name: "Index",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
.contentBody {
  min-height: 83vh;
}
</style>