<template>
  <div class="bottom-bg">
    <div class="bottom-wrapper">
      <p className="bottom-word">
        欢迎光临，本博客已上线
        {{years}}年{{days}}天{{hours}}时{{minutes}}分{{seconds}}秒，访问量{{looknumber}}次
      </p>

      <p className="bottom-word">
        Copyright © 2019-2021 mcyang.net All Rights Reserved.
        <a href="http://beian.miit.gov.cn" className="bottom-href"
          >京ICP备17048155号-1</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import request from '../../utils/axios'
export default {
  name: 'Footer',
  data() {
    return {
      looknumber: 0,
      years: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  },
  mounted() {
    var that = this
    request({
      method: 'get',
      url: '/api/record/detail',
    }).then((res) => {
      // console.log(res)
      if (res.data.code === '001') {
        const resultData = res.data.data
        let createTime = resultData.servertime - resultData.onlinetime
        that.looknumber = resultData.looknumber,
        that.timeCount(createTime)
      }
    })
  },
  methods: {
    timeCount(liveTime) {
      const that = this
      setInterval(function () {
        let time = liveTime
        let years = Math.floor(time / (1000 * 60 * 60 * 24 * 365))
        if (years > 0) {
          time = time - years * 1000 * 60 * 60 * 24 * 365
        }
        let days = Math.floor(time / (1000 * 60 * 60 * 24))
        if (days > 0) {
          time = time - days * 1000 * 60 * 60 * 24
        }
        let hours = Math.floor(time / (1000 * 60 * 60))
        if (hours > 0) {
          time = time - hours * 1000 * 60 * 60
        }
        let minutes = Math.floor(time / (1000 * 60))
        if (minutes > 0) {
          time = time - minutes * 1000 * 60
        }
        let seconds = Math.floor(time / 1000)
        that.years = years
        that.days = days
        that.hours = hours
        that.minutes = minutes
        that.seconds = seconds
        liveTime = liveTime + 1000
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
@import url('./Footer.less');
</style>