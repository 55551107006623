<template>
  <div>test page</div>
  <div>{{ text }}</div>
  <div>{{ student.name }} --- {{ doubleAge }}</div>
  <div>{{ student.teacher.name }} --- {{ student.teacher.age }}</div>
  <button @click="btnClick">click</button>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      text: 111111,
      student: {
        name: "xggg",
        num: 20,
        teacher: {
          name: "sam",
          age: "33",
        },
      },
    };
  },
  computed: {
    doubleAge() {
      return this.student.num * 2;
    },
  },
  watch: {
    text(oldVal, val) {
      console.log(oldVal, val);
    },
    // student(oldVal, val) {
    //   //拿不到 oldVal val
    //   console.log(oldVal, val);
    // },
    student: {
      handler(oldVal, val) {
        console.log(oldVal, val);
      },
      deep: true,
    },
  },
  methods: {
    btnClick() {
      this.text = 22222;
      this.student.num = 40;
    },
  },
  mounted() {
    const data = {};
    let name = "xggg";
    Object.defineProperty(data, "name", {
      get: function () {
        console.log("get");
        return name;
      },
      set: function (newVal) {
        console.log("set");
        name = newVal;
      },
    });
    console.log(data.name);
    data.name = "2222";
    console.log(data.name);
  },
};
</script>

<style lang="less" scoped>
</style>