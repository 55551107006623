<template>
  <div className="about-wrapper clearfix">
    <div class="about-component">
      <div class="content" v-html="content"></div>
    </div>
    <div class="info-component">
      <Info></Info>
    </div>
  </div>
</template>

<script>
import Info from "common/info/Info.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "About",
  components: {
    Info,
  },
  data() {
    return {
      text: "aboutaboutabout",
    };
  },
  computed: mapState({
    content: (state) => state.about.content,
  }),
  mounted() {
    this.getAboutData();
  },
  methods: {
    ...mapActions({
      getAboutData: "about/getAboutData",
    }),
  },
};
</script>

<style lang="less" scoped>
@import url("./about.less");
</style>