import request from "../../utils/axios";
import { timeToDate } from "../../utils/func";

export default {
  namespaced: true,
  state: () => ({
    title: "",
    content: "",
    changeTime: "",
    fixStatus: true,
  }),
  actions: {
    getDetailData({ commit }, payload) {
      request({
        method: "get",
        url: "/api/blog/detail",
        params: {
          id: payload.id,
        },
      }).then((res) => {
        if (res.data.code === "001") {
          var result = res.data.data;
          result.changeTime = timeToDate(result.createtime);
          commit("changeDetailData",result);
        }
      });
    },
  },
  mutations: {
    changeDetailData(state, payload) {
      state.title = payload.title
      state.content = payload.content
      state.changeTime = payload.changeTime
    },
  },
};
