import request from "../../utils/axios";
export default {
  namespaced: true,
  state: () => ({
    content: "",
  }),
  actions: {
    getAboutData({ commit }) {
      request({
        method: "get",
        url: "/api/about/get",
      }).then((res) => {
        console.log(res);
        if (res.data.code === "001") {
          var result = res.data.data;
          commit("changeAboutData", { result });
        }
      });
    },
  },
  mutations: {
    changeAboutData(state, payload) {
      state.content = payload.result.content;
    },
  },
};
