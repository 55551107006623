import request from "../../utils/axios";
import { timeToDate } from "../../utils/func";

export default {
  namespaced: true,
  state: () => ({
    listData: [],
    totalBlogCount: 0,
  }),
  actions: {
    getBlogList({ commit }, payload) {
      console.log(payload);
      request({
        method: "get",
        url: "/api/blog/list",
        params: {
          offset: payload.page  * 10,
          limit: 10,
        },
      }).then((res) => {
        const result = res.data.data;
        const list = result.listData;
        const totalCount = result.totleCount;
        if (res.data.code === "001") {
          for (let i = 0; i < list.length; i++) {
            list[i].changeTime = timeToDate(list[i].createtime);
            list[i].text = JSON.parse(
              JSON.stringify(list[i].content).replace(/<\/?.+?\/?>/g, "")
            );
          }
          commit("changeBlogList", {
            list,
            totalCount
          });
        }
      });
    },
  },
  mutations: {
    changeBlogList(state, payload) {
      state.listData = payload.list,
      state.totalBlogCount = payload.totalCount
    },
  },
};
