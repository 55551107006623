const environment = process.env.NODE_ENV;

const host = {
	production: 'https://www.mcyang.net:8000',
	development: 'http://127.0.0.1:8000'
};

const uploadHost = {
	production: 'static.imsummer.cn/',
	development: 'static-dev.imsummer.cn/'
}

export const ServerEnv = host[environment];
export const UploadEnv = uploadHost[environment];

